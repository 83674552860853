import {Dropzone} from "dropzone";
import {Select2} from "select2";

$(() => {
    $('#referance_product').on('keyup', function (event) {
        event.preventDefault();
        $('.docs').html('');
        if ($(this).val().length > 2) {
            let ref = $(this).val();
            CheckReference(ref);
        }
    });
    if ($('#referance_product').length > 0) {
        if ($('#referance_product').val() != "") {
            let ref = $("#referance_product").val();
            CheckReference(ref);
        }
    }
    initSAVDropzone();
    $(document).on('click', '.parcours-sav-popup .psav-ppu-b button.close-btn', function (e) {
        $('.parcours-sav-popup').remove();
    });
    $(document).on("click", "#submitform_mu", function () {
        $('#user_mail').val($('input[name="mail"]').val());
    });
    /** piece detaches */
    $('.parcours-sav .cpd-choice .sav-cpd-bchoice .cpd-yn a').on("click", function () {
        $(this).addClass('active');
        if ($(this).hasClass('cpd-y'))
            $('.parcours-sav .cpd-choice .sav-cpd-bchoice.c2').show();
    });

    if ($('.product_id_pc').length > 0) {

        $('.product_id_pc').select2();
        let id_product = $('#product_id_pc').val();
        let ref =  $("#product_id_pc option:selected").text();
        getDoc_cpd(id_product,ref);

        $('#product_id_pc').on('change', function (event) {
            event.preventDefault();
            let id_product = $('#product_id_pc').val();
            let ref =  $("#product_id_pc option:selected").text();
            getDoc_cpd(id_product,ref);
        });
    }
        /* fix click multi time simultaneously */
          $("#form_other_request").submit(function() {
                $(this).submit(function() {
                    return false;
                });
                return true;
          });

});

function CheckReference(ref) {
    const url = $(".form-dd").data('action');

    $.ajax({
        url: url,
        type: 'POST',
        cache: false,
        dataType: 'json',
        async: true,
        data: {
            reference: ref
        },
        beforeSend: function () {
            $('.docs').html('Chargement...');
        },
        success: function (result) {
            if (result.found == true) {
                $('.input-textarea').removeAttr('disabled');
                if (result.docs == true) {
                    $('.docs').html(result.message);
                } else {
                    $('.docs').html('');
                }
                $('.description-ref').html(result.descriptionref)
                $('.submit-dd').removeAttr('disabled');
            } else {
                $('.description-ref').html(result.link);
                $('.input-textarea').attr('disabled', 'disabled');
                $('.submit-dd').attr('disabled', 'disabled');
                $('.docs').html('<strong class="text-danger">' + result.message + '</strong>');
            }
        }
    });
}


function initSAVDropzone() {
    if ($("#sav-upload-file").length > 0) {
        $("#sav-upload-file").attr('data-url')
        $("#sav-upload-file").dropzone({
            autoProcessQueue: true,
            maxFilesize: 20,
            maxFiles: 10,
            paramName: 'uploadedFile',
            url: $("#sav-upload-file").attr('data-url'),
            clickable: true,
            acceptedFiles: "image/jpg,image/jpeg,image/png,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/mp4,video/avi,video/wmv,video/webm",
            uploadMultiple: false,
            addRemoveLinks: true,
            params: function (files, xhr, chunk) {
                return {
                    ref: $('#ref-product').val()
                }
            },
            init: function () {
                let filepath = $('#file_path').val();
                this.on("success", function (file, response) {
                    $('#save_other_request').prop('disabled', false);
                    var result = jQuery.parseJSON(response);
                    if (typeof result.error != 'undefined') {
                        $(file.previewElement).addClass("dz-error").find('.dz-error-message').text(result.error);
                    }
                    if (filepath == '') {
                        filepath = result.filename;
                    } else {
                        filepath = filepath + ';' + result.filename;
                    }
                    $('#file_path').val(filepath);
                });
                this.on("maxfilesexceeded", function (file) {
                    this.removeFile(file);
                });

                this.on('error', function (file, response) {
                    $(file.previewElement).addClass("dz-error").find('.dz-error-message').text(response);
                    if ($('body .parcours-sav-popup').length == 0) {
                        $('body').append(
                            '<div class="parcours-sav-popup">' +
                            '<div class="psav-ppu-b">' +
                            '<button class="close close-btn"><i class="fas fa-times-circle"></i></button>' +
                            '<i class="fas fa-times-circle error-icon"></i>' +
                            '<span>' + response + '</span>' +
                            '<button class="return-btn close-btn">RETOUR</button>' +
                            '</div>' +
                            '</div>'
                        );
                    }
                    this.removeFile(file);
                });
            },
            error: function (file, message) {
                console.log(message);
            },

            dictDefaultMessage: AfterSalesServiceMessages['DropZoneTextDefaultMessage'],
            dictFallbackMessage: AfterSalesServiceMessages['DropZoneTextFallbackMessage'],
            dictFallbackText: AfterSalesServiceMessages['DropZoneTextFallbackText'],
            dictFileTooBig: AfterSalesServiceMessages['DropZoneTextFileTooBig'],
            dictInvalidFileType: AfterSalesServiceMessages['DropZoneTextInvalidFileType'],
            dictResponseError: AfterSalesServiceMessages['DropZoneTextResponseError'],
            dictCancelUpload: AfterSalesServiceMessages['DropZoneTextCancelUpload'],
            dictCancelUploadConfirmation: AfterSalesServiceMessages['DropZoneTextCancelUploadConfirmation'],
            dictRemoveFile: AfterSalesServiceMessages['DropZoneTextRemoveFile'],
            dictMaxFilesExceeded: "Vous ne pouvez pas déposer plus de 10 fichiers."
            //dictMaxFilesExceeded: AfterSalesServiceMessages['DropZoneTextMaxFilesExceeded']
        });
    }
}

/***User Manual***/
$('.ref-submit').on('click', function (event) {
    event.preventDefault();
    $('.errors-mu').addClass('d-none');
    let ref = $('#ref-mu').val();
    if (ref != '') {
        CheckRef_mu(ref);
    } else {
        $('.errors-mu').removeClass('d-none');
        $('.errors-mu .text').text('Champ obligatoire');
    }
});

function CheckRef_mu(ref) {
    const url = $(".form-mu").data('action');
    $('.description-ref').html("");
    $.ajax({
        url: url,
        type: 'POST',
        cache: false,
        dataType: 'json',
        data: {
            reference: ref
        },
        beforeSend: function () {
            $('.docs').html('Chargement...');
        },
        success: function (result) {
            if (result.found == false) {
                $('.docs').html('<strong class="text-danger" >' + result.message + '</strong>');
                $('.description-ref').html('Si vous ne connaissez pas la référence, <a href="/sav-autres-demandes">cliquez ici</a> pour nous envoyer des photos de votre produit');
            } else {
                $('.description-ref').html(result.descriptionref)

                $('.input-textarea').removeAttr('disabled');
                $('.errors-mu').addClass('d-none');
                if (result.docs != true) {
                    $('.errors-mu').removeClass('d-none');
                    $('.errors-mu .text').text('Votre notice n’est pas disponible en ligne');
                }
                $('.docs').html(result.message);
            }


        }
    });
}

/**piece detache **/
function getDoc_cpd(id_product,ref) {
    const url = $(".form-sparepart").data('action');
    $.ajax({
        url: url,
        type: 'POST',
        cache: false,
        dataType: 'json',
        data: {
            product_id: id_product,
            ref: ref
        },
        beforeSend: function () {
            $('.docs').html('Chargement...');
        },
        success: function (result) {
            if (result.found == true) {
                $('.docs').html(result.message);
            } else {
                $('.docs').html('');
            }
        }
    });
}
